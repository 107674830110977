import { Fragment as _Fragment, jsx as _jsx } from "react/jsx-runtime";
import detectCurrentCheckId from "./detectCurrentCheckId";
import { getCheckIdFromLocalStorage, getCheckIdFromQueryParams, setCheckIdInLocalStorage, setCheckVerificationStatusInLocalStorage, hideVerifyIdButton, checkVerificationStatusByLocalStorage, enableCheckoutButtons, setCheckIdInWcSession, } from "./precheckout";
import React from "react";
import { getWcShopName, getWcCustomerId, getWcShopSettings, getShopifyShopName, getShopifyCustomerId, getShopifyOrderId, getShopSettings, getRegistrationSettings, getCheckByCustomer, createWcCustomerCheck, removeLocalStorageCheckId, unmountAllFlows, } from "../helpers";
import console from "../log";
import { isEmpty } from "lodash";
import axios from "axios";
import intersection from "lodash/intersection";
import fetchOverride from "./shopify/fetchOverride";
import XMLHttpRequestOverride from "./shopify/XMLHttpRequestOverride";
import { associateWcIdCheckWithOrder } from "./postcheckout";
export const SHOPIFY_POST_CHECKOUT_ENTRY_SELECTOR = ".section__content";
export const MOUNT_POINT_ID = "real-id-flow";
export const MOUNT_POINT_CLASSNAME = "real-id-flow";
export const WC_PRE_CHECKOUT_ENTRY_SELECTORS = [
    ".wc-proceed-to-checkout",
    "form[name='checkout']",
    "button.checkout",
    "a.checkout",
    // Make Offer Plugin support: https://woocommerce.com/products/make-an-offer-for-woocommerce/
    ".woocommerce-make-offer-form-submit-button",
    ".real-id-checkout-button",
    ".verify-id-prompt",
    ".wp-block-woocommerce-proceed-to-checkout-block",
    ".wc-block-components-checkout-place-order-button",
    ".wc-block-cart__submit-button",
    "#wc-square-digital-wallet",
];
export const WC_POST_CHECKOUT_ENTRY_SELECTORS = [
    ".woocommerce-order",
    ".real-id-post-checkout-mount",
    ".page-checkout",
    "div[data-block-name='woocommerce/order-confirmation-status']",
    ".woocommerce-order-received", // more weird elementor, this is the outer wrapper of the <body>, make sure this is the last element
];
export const WC_REGISTRATION_ENTRY_SELECTORS = ["body"];
export const SHOPIFY_PRE_CHECKOUT_ENTRY_SELECTORS = [
    // NARKENO-BRANDS.myshopify.com
    "#cart-checkout",
    "#cart-sidebar-checkout",
    ".shopify-payment-button",
    // END NARKENO-BRANDS.myshopify.com
    "input[name='checkout']",
    "button[name='checkout']",
    ".real-id-checkout-button",
    ".verify-id-prompt",
    "#checkout",
    "a[href='/checkout']",
    "a[href='/checkout/']",
    // SignPanda on rubixink.myshopify.com
    "#signpanda-btn",
    "#shop-pay-button-link",
    // racquetguys1.myshopify.com dropdown cart
    "#dropdown-cart .btn-checkout",
    // uncomment this to see the redundant verify ID cta issue
    // "button[data-testid='Checkout-button']",
    // 'button[data-testid="Checkout-button"]',
];
export const SHOPIFY_REGISTRATION_ENTRY_SELECTORS = [
    "#real-id-hard-gate",
    ".real-id-hard-gate",
    "main#MainContent",
    "main.main-content",
    // uvapeshop.myshopify.com, which has main#site-main, but why be overly specific?
    "main",
    // vaporbear.myshopify.com has a weird theme
    "#jas-wrapper",
];
export async function loadPlatformBootstrapProps(platform) {
    switch (platform) {
        case "shopify":
            const shopSettings = await getShopSettings(getShopifyShopName());
            const root = window.Shopify?.routes?.root;
            if (!root) {
                console.debug("Real ID :: No root available on this page");
                return {
                    shopSettings,
                    cart: { items: [] },
                };
            }
            try {
                const { data } = await axios.get(`${root}cart.js`);
                return {
                    shopSettings,
                    cart: data,
                };
            }
            catch (e) {
                console.debug("Real ID :: No cart available on this page");
                return {
                    shopSettings,
                    cart: { items: [] },
                };
            }
        case "hosted":
            return {};
        case "wc":
            return {
                shopSettings: await getWcShopSettings(),
            };
        default:
            throw new Error("Unable to bootstrap, unsupported platform type");
    }
}
export const NullStrategy = {
    strategy: "null",
    entrySelectors: ["body"],
    bootstrap: () => { },
    isEligble: () => true,
    createMount: () => null,
    rootComponent: () => () => _jsx(_Fragment, {}),
    getAdditionalEnvironmentProps: async () => { },
    onIdCheckCreate: () => { },
    onIdCheckError: () => { },
    onIdCheckFail: () => { },
    onIdCheckSuccess: () => { },
};
export const definitions = {
    hosted: [
        {
            strategy: "hosted",
            bootstrap: () => { },
            isEligble: () => true,
            entrySelectors: ["#" + MOUNT_POINT_ID],
            getAdditionalEnvironmentProps: async () => ({
                checkId: detectCurrentCheckId(),
            }),
            createMount: () => {
                const mount = document.querySelector("#" + MOUNT_POINT_ID);
                return mount ? [mount] : null;
            },
            rootComponent: () => React.lazy(() => import("../App")),
            onIdCheckCreate: () => { },
            onIdCheckError: () => { },
            onIdCheckFail: () => { },
            onIdCheckSuccess: () => { },
        },
        {
            strategy: "sdk",
            entrySelectors: ["#real-id-mount"],
            bootstrap: () => { },
            isEligble: () => {
                const localCheckId = getCheckIdFromLocalStorage();
                const isVerifiedLocally = checkVerificationStatusByLocalStorage(localCheckId || "");
                if (isVerifiedLocally) {
                    console.log("Verified already by local storage");
                    return false;
                }
                return true;
            },
            getAdditionalEnvironmentProps: async () => {
                const shopName = getShopifyShopName();
                return {
                    checkId: getCheckIdFromLocalStorage() || getCheckIdFromQueryParams(),
                    shopName,
                    shopSettings: await getShopSettings(shopName),
                };
            },
            createMount: ({ entrySelectors }) => {
                const localCheckId = getCheckIdFromLocalStorage();
                if (localCheckId) {
                    console.debug(`Real ID :: check ID found in local storage :: check ${localCheckId}`);
                    if (checkVerificationStatusByLocalStorage(localCheckId)) {
                        console.debug(`Real ID :: check already verified :: check ${localCheckId} :: not creating a mount`);
                        unmountAllFlows();
                        return null;
                    }
                }
                const nodes = document.querySelectorAll(entrySelectors.join(", "));
                if (!nodes) {
                    return null;
                }
                const mountEls = [...nodes]
                    // only create ID check prompts for unmounted entry elements
                    .filter((el) => !el.getAttribute("real-id-mounted"))
                    .map((el) => {
                    console.debug(`Real ID :: pre-checkout create mount point :: entry ${el} :: className ${el.className} :: has real-id-mounted attribute? ${el.getAttribute("real-id-mounted")}`);
                    const mountEl = document.createElement("div");
                    mountEl.classList.add("real-id-flow");
                    // mountEl.innerHTML = "Mount here";
                    el.parentElement?.append(mountEl);
                    return mountEl;
                });
                return mountEls;
            },
            rootComponent: () => React.lazy(() => import("../PreCheckoutApp")),
            onIdCheckCreate: (check) => {
                setCheckIdInLocalStorage(check.id);
            },
            onIdCheckError: (error) => {
                if (error === "check_already_completed" ||
                    error === "verified_customer") {
                    unmountAllFlows();
                }
            },
            onIdCheckFail: () => { },
            onIdCheckSuccess: (check) => {
                // store IDv success in localstorage
                setCheckVerificationStatusInLocalStorage(check.id, true);
                // seems redundant, but also update the checkId in local storage
                // this is because multiple instances of the flow can be on one page. For instance, if the page has two mountable elements ("Buy it now" and side cart) then two instances of the flow start, and there are two separate ID checks made
                // let the completed check "win"
                setCheckIdInLocalStorage(check.id);
                unmountAllFlows();
            },
        },
    ],
    shopify: [
        {
            strategy: "pre-checkout",
            entrySelectors: SHOPIFY_PRE_CHECKOUT_ENTRY_SELECTORS,
            getAdditionalEnvironmentProps: async () => {
                const shopName = getShopifyShopName();
                return {
                    checkId: getCheckIdFromLocalStorage() || getCheckIdFromQueryParams(),
                    shopName,
                    shopSettings: await getShopSettings(shopName),
                };
            },
            bootstrap: () => { },
            isEligble: ({ shopSettings, cart }, initialize) => {
                const isPrecheckoutEnabled = !!shopSettings?.deliveryMethods?.includes("preCheckout") ||
                    shopSettings?.deliveryMethod === "pre-checkout";
                console.debug(`Real ID :: pre-checkout enabled :: ${isPrecheckoutEnabled}`);
                if (!isPrecheckoutEnabled) {
                    return false;
                }
                XMLHttpRequestOverride(initialize);
                fetchOverride(initialize);
                if (isPrecheckoutEnabled &&
                    !!!shopSettings?.requiredIdProductsEnabled) {
                    return true;
                }
                console.log(`Real ID :: required ID products are enabled. Checking cart.`);
                const currentItems = cart?.items.map((item) => `gid://shopify/Product/${item.product_id}`);
                const maybeIdRequiredProductsInCart = intersection(currentItems, shopSettings?.requiredIdProducts || []);
                const isProductTriggered = shopSettings?.requiredIdProductsEnabled &&
                    maybeIdRequiredProductsInCart.length > 0;
                console.debug(`Real ID :: required ID products enabled :: ${shopSettings?.requiredIdProductsEnabled}`);
                console.debug(`Real ID :: required ID products list :: ${shopSettings?.requiredIdProducts?.join(",")}`);
                console.debug(`Real ID :: cart contents :: ${currentItems?.join(",")}`);
                console.debug(`Real ID :: # of required ID products in cart :: ${maybeIdRequiredProductsInCart.length > 0}`);
                if (isProductTriggered) {
                    return true;
                }
                if (shopSettings?.requiredIdSubdomains &&
                    shopSettings?.requiredIdSubdomains?.length > 0 &&
                    shopSettings?.requiredIdSubdomains.includes(window.location.hostname)) {
                    console.debug(`Real ID :: subdomain requires ID verification :: ${window.location.hostname} in ${shopSettings?.requiredIdSubdomains.join(", ")}`);
                    return true;
                }
                console.debug("Real ID :: product not present :: registering event listener in case of ajax cart ");
                // The intention of this hack was to detect when customers removed ID verification required products from their cart
                // however, it's now interfering with NomadPlus's integration when they're manually implementing ID checks
                // Hook this logic into the /cart/remove.js event instead, since page refreshes will have the latest cart
                // enableCheckoutButtons(SHOPIFY_PRE_CHECKOUT_ENTRY_SELECTORS);
                // hideVerifyIdButton();
                return false;
            },
            createMount: ({ entrySelectors }) => {
                const localCheckId = getCheckIdFromLocalStorage();
                if (localCheckId) {
                    console.debug(`Real ID :: check ID found in local storage :: check ${localCheckId}`);
                    if (checkVerificationStatusByLocalStorage(localCheckId)) {
                        console.debug(`Real ID :: check already verified :: check ${localCheckId} :: not creating a mount`);
                        enableCheckoutButtons(SHOPIFY_PRE_CHECKOUT_ENTRY_SELECTORS);
                        hideVerifyIdButton();
                        return null;
                    }
                }
                const nodes = document.querySelectorAll(entrySelectors.join(", "));
                if (!nodes) {
                    return null;
                }
                const mountEls = [...nodes]
                    // only create ID check prompts for unmounted entry elements
                    .filter((el) => !el.getAttribute("real-id-mounted"))
                    .map((el) => {
                    console.debug(`Real ID :: pre-checkout create mount point :: entry ${el} :: className ${el.className} :: has real-id-mounted attribute? ${el.getAttribute("real-id-mounted")}`);
                    const mountEl = document.createElement("div");
                    mountEl.classList.add("real-id-flow");
                    mountEl.innerHTML = "Mount here";
                    el.parentElement?.append(mountEl);
                    return mountEl;
                });
                return mountEls;
            },
            onIdCheckCreate: (check) => {
                setCheckIdInLocalStorage(check.id);
            },
            onIdCheckError: (error) => {
                // enable WC checkout depending on the error type
                if (error === "check_already_completed" ||
                    error === "verified_customer") {
                    enableCheckoutButtons(SHOPIFY_PRE_CHECKOUT_ENTRY_SELECTORS);
                    hideVerifyIdButton();
                }
            },
            onIdCheckSuccess: (check) => {
                if (!check) {
                    console.error("missing check, so unable to continue");
                    return;
                }
                console.debug(`Real ID :: onIdCheckSuccess :: shopify pre-checkout :: check ${check.id}`);
                // enable Shopify checkout
                enableCheckoutButtons(SHOPIFY_PRE_CHECKOUT_ENTRY_SELECTORS);
                hideVerifyIdButton();
                // store IDv success in localstorage
                setCheckVerificationStatusInLocalStorage(check.id, true);
                // seems redundant, but also update the checkId in local storage
                // this is because multiple instances of the flow can be on one page. For instance, if the page has two mountable elements ("Buy it now" and side cart) then two instances of the flow start, and there are two separate ID checks made
                // let the completed check "win"
                setCheckIdInLocalStorage(check.id);
            },
            rootComponent: () => React.lazy(() => import("../PreCheckoutApp")),
            onIdCheckFail: () => { },
        },
        {
            strategy: "post-checkout",
            entrySelectors: [SHOPIFY_POST_CHECKOUT_ENTRY_SELECTOR],
            bootstrap: () => { },
            isEligble: ({ shopSettings, cart }) => {
                if (!getShopifyOrderId()) {
                    console.debug(`Real ID :: shopify order missing, exiting`);
                    return false;
                }
                if (shopSettings?.deliveryMethods &&
                    (shopSettings?.deliveryMethods.includes("post-checkout") ||
                        shopSettings?.deliveryMethods.includes("checkout"))) {
                    console.debug(`Real ID :: deliveryMethods contains post-checkout or checkout`);
                    return true;
                }
                if (shopSettings?.deliveryMethod === "post-checkout") {
                    console.debug(`Real ID :: deliveryMethod is post-checkout`);
                    return true;
                }
                return false;
            },
            getAdditionalEnvironmentProps: async () => {
                return {
                    shopName: getShopifyShopName(),
                    customerId: getShopifyCustomerId(),
                    orderId: getShopifyOrderId(),
                };
            },
            createMount: ({ entrySelectors }) => {
                const nodes = document.querySelectorAll(entrySelectors.join(", "));
                if (!nodes) {
                    return null;
                }
                console.debug("Create mount element for Real ID post checkout wizard");
                const mountEl = document.createElement("div");
                mountEl.setAttribute("id", MOUNT_POINT_ID);
                mountEl.setAttribute("class", "content-box ri-h-700");
                // inject as a child in the shopify container
                nodes[0].prepend(mountEl);
                return [mountEl];
            },
            rootComponent: () => React.lazy(() => import("../App")),
            onIdCheckCreate: () => { },
            onIdCheckError: () => { },
            onIdCheckFail: () => { },
            onIdCheckSuccess: () => { },
        },
        {
            strategy: "registration",
            entrySelectors: SHOPIFY_REGISTRATION_ENTRY_SELECTORS,
            getAdditionalEnvironmentProps: async () => {
                const shopName = getShopifyShopName();
                return {
                    checkId: getCheckIdFromLocalStorage() || getCheckIdFromQueryParams(),
                    shopName,
                    shopSettings: await getShopSettings(shopName),
                    customerId: getShopifyCustomerId(),
                };
            },
            isEligble: ({ shopSettings }) => {
                return (!!shopSettings?.deliveryMethods?.includes("registration") ||
                    shopSettings?.deliveryMethod === "registration");
            },
            bootstrap: async () => {
                const shopName = getShopifyShopName();
                const customerId = getShopifyCustomerId();
                const registrationSettings = await getRegistrationSettings(shopName);
                const shopSettings = await getShopSettings(shopName);
                const check = await getCheckByCustomer(shopName, customerId);
                if (check &&
                    check.step === "completed" &&
                    check?.job?.result?.success) {
                    setCheckIdInLocalStorage(check?.id);
                    setCheckVerificationStatusInLocalStorage(check?.id, check?.job?.result?.success && check?.step === "completed");
                }
                const props = {
                    registrationSettings,
                    shopSettings,
                    check,
                };
                return props;
            },
            createMount: ({ entrySelectors, props }) => {
                // is the customer currently on the login or registration pages? If so, do nothing
                console.debug(`Real ID :: testing ${window?.location.pathname} against ${[
                    "/account",
                    "/account/",
                    "/account/login",
                    "/account/register",
                    "/account/invalid_token",
                    "/account/challenge",
                    ...(props?.shopSettings?.registrationSettings?.allowedPages || []),
                ].join(",")}`);
                if ([
                    "/account/",
                    "/account",
                    "/account/login",
                    "/account/register",
                    "/account/invalid_token",
                    "/account/challenge",
                    ...(props?.shopSettings?.registrationSettings?.allowedPages || []),
                ].includes(window?.location?.pathname)) {
                    console.debug(`Real ID :: on an allowed page, not creating a mount`);
                    return null;
                }
                // is the customer verified by local storage? If so, do nothing
                const localCheckId = getCheckIdFromLocalStorage();
                if (localCheckId) {
                    console.debug(`Real ID :: check ID found in local storage :: check ${localCheckId}`);
                    if (checkVerificationStatusByLocalStorage(localCheckId)) {
                        console.debug(`Real ID :: check already verified :: check ${localCheckId} :: not creating a mount`);
                        return null;
                    }
                }
                if (!props?.shopSettings?.registrationSettings ||
                    isEmpty(props?.shopSettings?.registrationSettings)) {
                    console.debug(`Real ID :: missing registrationSettings, not mounting`);
                    return null;
                }
                const nodes = document.querySelectorAll(entrySelectors.join(", "));
                if (!nodes) {
                    console.debug(`Real ID :: missing query selectors, not mounting`);
                    return null;
                }
                // is the user already verified?
                const mountEls = [...nodes].map((el) => {
                    console.debug(`Real ID :: registration create mount point :: entry ${el}`);
                    const mountEl = document.createElement("div");
                    mountEl.classList.add("real-id-flow");
                    mountEl.innerHTML = "";
                    el.insertAdjacentElement("afterend", mountEl);
                    return mountEl;
                });
                return mountEls;
            },
            onIdCheckCreate: (check) => {
                setCheckIdInLocalStorage(check.id);
            },
            onIdCheckError: (error) => {
                // enable WC checkout depending on the error type
                if (error === "check_already_completed" ||
                    error === "verified_customer") {
                    hideVerifyIdButton();
                }
            },
            onIdCheckSuccess: (check) => {
                if (!check) {
                    console.error("missing check, so unable to continue");
                    return;
                }
                console.debug(`Real ID :: onIdCheckSuccess :: shopify pre-checkout :: check ${check.id}`);
                // enable WC checkout
                // store IDv success in localstorage
                hideVerifyIdButton();
                setCheckVerificationStatusInLocalStorage(check.id, true);
                document?.getElementById("real-id-hidden-elements")?.remove();
            },
            rootComponent: () => React.lazy(() => import("../RegistrationApp")),
            onIdCheckFail: () => { },
        },
        {
            strategy: "registration-webhook",
            entrySelectors: SHOPIFY_REGISTRATION_ENTRY_SELECTORS,
            getAdditionalEnvironmentProps: async () => {
                const shopName = getShopifyShopName();
                return {
                    checkId: getCheckIdFromLocalStorage() || getCheckIdFromQueryParams(),
                    shopName,
                    shopSettings: await getShopSettings(shopName),
                    customerId: getShopifyCustomerId(),
                };
            },
            isEligble: ({ shopSettings }) => {
                const customerId = getShopifyCustomerId();
                const isRegistrationWebhookEnabled = !!shopSettings?.deliveryMethods?.includes("registration-webhook") ||
                    shopSettings?.deliveryMethod === "registration-webhook";
                return (customerId && // only ID gate when customers are logged in
                    isRegistrationWebhookEnabled &&
                    !!shopSettings?.registrationWebhookSettings?.inStoreIDv);
            },
            bootstrap: async () => {
                const shopName = getShopifyShopName();
                const customerId = getShopifyCustomerId();
                if (!customerId) {
                    removeLocalStorageCheckId();
                }
                const registrationSettings = await getRegistrationSettings(shopName);
                const shopSettings = await getShopSettings(shopName);
                const check = await getCheckByCustomer(shopName, customerId);
                if (check) {
                    setCheckIdInLocalStorage(check?.id);
                }
                if (check &&
                    check.step === "completed" &&
                    check?.job?.result?.success) {
                    setCheckVerificationStatusInLocalStorage(check?.id, check?.job?.result?.success && check?.step === "completed");
                }
                const props = {
                    registrationSettings,
                    shopSettings,
                    check,
                };
                return props;
            },
            createMount: ({ entrySelectors, props }) => {
                // is the customer currently on the login or registration pages? If so, do nothing
                console.debug(`Real ID :: testing ${window?.location.pathname} against ${[
                    "/account/login",
                    "/account/register",
                    "/account/invalid_token",
                    "/account/challenge",
                    // ...(props?.shopSettings?.registrationSettings?.allowedPages || []),
                ].join(",")}`);
                if ([
                    "/account/login",
                    "/account/register",
                    "/account/invalid_token",
                    "/account/challenge",
                    // ...(props?.shopSettings?.registrationSettings?.allowedPages || []),
                ].includes(window?.location?.pathname)) {
                    console.debug(`Real ID :: on an allowed page, not creating a mount`);
                    return null;
                }
                // is the customer verified by local storage? If so, do nothing
                const localCheckId = getCheckIdFromLocalStorage();
                if (localCheckId) {
                    console.debug(`Real ID :: check ID found in local storage :: check ${localCheckId}`);
                    if (checkVerificationStatusByLocalStorage(localCheckId)) {
                        console.debug(`Real ID :: check already verified :: check ${localCheckId} :: not creating a mount`);
                        return null;
                    }
                }
                if (!props?.shopSettings?.registrationSettings ||
                    isEmpty(props?.shopSettings?.registrationSettings)) {
                    console.debug(`Real ID :: missing registrationSettings, not mounting`);
                    return null;
                }
                const nodes = document.querySelectorAll(entrySelectors.join(", "));
                if (!nodes) {
                    console.debug(`Real ID :: missing query selectors, not mounting`);
                    return null;
                }
                // is the user already verified?
                const mountEls = [...nodes].map((el) => {
                    console.debug(`Real ID :: registration create mount point :: entry ${el}`);
                    const mountEl = document.createElement("div");
                    mountEl.classList.add("real-id-flow");
                    mountEl.innerHTML = "";
                    el.insertAdjacentElement("afterend", mountEl);
                    return mountEl;
                });
                return mountEls;
            },
            onIdCheckCreate: (check) => {
                setCheckIdInLocalStorage(check.id);
            },
            onIdCheckError: (error) => {
                // enable WC checkout depending on the error type
                if (error === "check_already_completed" ||
                    error === "verified_customer") {
                    hideVerifyIdButton();
                }
            },
            onIdCheckSuccess: (check) => {
                if (!check) {
                    console.error("missing check, so unable to continue");
                    return;
                }
                console.debug(`Real ID :: onIdCheckSuccess :: shopify pre-checkout :: check ${check.id}`);
                // enable WC checkout
                // store IDv success in localstorage
                hideVerifyIdButton();
                setCheckVerificationStatusInLocalStorage(check.id, true);
                document?.getElementById("real-id-hidden-elements")?.remove();
            },
            rootComponent: () => React.lazy(() => import("../RegistrationApp")),
            onIdCheckFail: () => { },
        },
    ],
    wc: [
        {
            strategy: "pre-checkout",
            entrySelectors: WC_PRE_CHECKOUT_ENTRY_SELECTORS,
            getAdditionalEnvironmentProps: async () => {
                return {
                    checkId: getCheckIdFromLocalStorage() || getCheckIdFromQueryParams(),
                    shopName: getWcShopName(),
                    // shopSettings are necessary to load preferredLang and theme _before_ the ID check is loaded
                    shopSettings: await getWcShopSettings(),
                };
            },
            bootstrap: () => { },
            isEligble: ({ shopSettings }, initialize) => {
                if (!shopSettings?.deliveryMethods?.includes("preCheckout") &&
                    shopSettings?.deliveryMethod !== "pre-checkout") {
                    console.debug(`Real ID :: pre-checkout isEligble test :: delivery method not enabled`);
                    return false;
                }
                if (shopSettings?.requiredIdCategoriesEnabled &&
                    shopSettings?.requiredIdCategories &&
                    window.realIdCart) {
                    console.debug(`Real ID :: pre-checkout isEligble test :: delivery method enabled and product specific exclusion rules enabled, checking cart contents`);
                    const maybeContainsCategories = intersection(shopSettings?.requiredIdCategories, window.realIdCart?.category_ids);
                    if (maybeContainsCategories?.length > 0) {
                        console.debug(`Real ID :: categories ${maybeContainsCategories.join(", ")} triggered an ID check`);
                        return true;
                    }
                    return false;
                }
                console.debug(`Real ID :: pre-checkout isEligble test :: delivery method enabled and product specific exclusion rules disabled`);
                return true;
            },
            createMount: ({ entrySelectors, initialize }) => {
                const localCheckId = getCheckIdFromLocalStorage();
                if (localCheckId) {
                    console.debug(`Real ID :: check ID found in local storage :: check ${localCheckId}`);
                    if (checkVerificationStatusByLocalStorage(localCheckId)) {
                        console.debug(`Real ID :: check already verified :: check ${localCheckId} :: not creating a mount`);
                        hideVerifyIdButton();
                        return null;
                    }
                }
                const nodes = document.querySelectorAll(entrySelectors.join(", "));
                if (!nodes) {
                    return null;
                }
                const mountEls = [...nodes]
                    .filter((el) => {
                    console.debug(`Entry element display: ${window.getComputedStyle(el).display}`);
                    return window.getComputedStyle(el).display !== "none";
                })
                    // only create ID check prompts for unmounted entry elements
                    .filter((el) => !el.getAttribute("real-id-mounted"))
                    .map((el) => {
                    console.debug(`Real ID :: pre-checkout create mount point :: entry ${el}`);
                    const mountEl = document.createElement("div");
                    mountEl.classList.add("real-id-flow");
                    mountEl.innerHTML = "Mount here";
                    el.parentElement?.append(mountEl);
                    // register a mutation observer in case some bs plugin tries to remove our synthetic button
                    console.debug(`Real ID :: registering lazarus`);
                    const observerOptions = {
                        childList: true,
                        attributes: false,
                        // Omit (or set to false) to observe only changes to the parent node
                        subtree: true,
                    };
                    const observer = new MutationObserver((mutationList, observer) => {
                        // does the change remove our button?
                        mutationList.forEach((mutation) => {
                            switch (mutation.type) {
                                case "childList":
                                    /* One or more children have been added to and/or removed
                                  from the tree.
                                  (See mutation.addedNodes and mutation.removedNodes.) */
                                    if (mutation.removedNodes) {
                                        mutation.removedNodes.forEach((node) => {
                                            // if our parent node has been deleted, let's re-initialize the app again
                                            if (node.contains(mountEl)) {
                                                console.debug(`Real ID :: parent element deleted by plugin :: reinitializing`);
                                                initialize();
                                            }
                                        });
                                    }
                                    break;
                            }
                        });
                    });
                    const maybeWCContainers = document.getElementsByClassName("woocommerce");
                    if (maybeWCContainers) {
                        [...maybeWCContainers].forEach((wcContainer) => {
                            observer.observe(wcContainer, observerOptions);
                        });
                    }
                    return mountEl;
                });
                return mountEls;
            },
            onIdCheckCreate: (check) => {
                setCheckIdInLocalStorage(check.id);
                setCheckIdInWcSession(check.id);
            },
            onIdCheckError: (error) => {
                // enable WC checkout depending on the error type
                if (error === "check_already_completed" ||
                    error === "verified_customer") {
                    hideVerifyIdButton();
                }
            },
            onIdCheckSuccess: (check) => {
                console.debug(`Real ID :: onIdCheckSuccess :: wc pre-checkout `);
                // enable WC checkout
                // store IDv success in localstorage
                hideVerifyIdButton();
                setCheckVerificationStatusInLocalStorage(check.id, true);
                // seems redundant, but it's possible for one page to have multiple flows
                setCheckIdInLocalStorage(check.id);
            },
            rootComponent: () => React.lazy(() => import("../PreCheckoutApp")),
            onIdCheckFail: () => { },
        },
        {
            strategy: "post-checkout",
            entrySelectors: WC_POST_CHECKOUT_ENTRY_SELECTORS,
            bootstrap: () => { },
            isEligble: () => {
                const localCheckId = getCheckIdFromLocalStorage();
                if (localCheckId) {
                    console.debug(`Real ID :: check ID found in local storage :: check ${localCheckId}`);
                    if (checkVerificationStatusByLocalStorage(localCheckId)) {
                        console.debug(`Real ID :: check already verified :: check ${localCheckId} :: not creating a mount`);
                        // asynchronously associate the ID check with the order
                        associateWcIdCheckWithOrder();
                        return false;
                    }
                }
                // only pages that have a realIdOrder in the thank you page are eligble
                return !!window.realIdOrder;
            },
            getAdditionalEnvironmentProps: async () => {
                return {
                    shopName: getWcShopName(),
                    order: window.realIdOrder,
                    order_id: window.realIdOrder?.id,
                    customer_id: window.realIdOrder?.customer?.id,
                };
            },
            createMount: ({ entrySelectors }) => {
                const nodes = document.querySelectorAll(entrySelectors.join(", "));
                if (!nodes || nodes.length == 0) {
                    return null;
                }
                console.debug("Create mount element for Real ID post checkout wizard");
                const entryEl = nodes[0];
                console.log(`Entry element :: ${entryEl.tagName.toLowerCase()} #${entryEl.id} ${entryEl.className}`);
                const mountEl = document.createElement("div");
                mountEl.setAttribute("id", MOUNT_POINT_ID);
                mountEl.classList.add("real-id-flow");
                // placing the block in the correct place if they're using the WooCommerce Block Checkout
                if (entryEl.getAttribute("data-block-name") ===
                    "woocommerce/order-confirmation-status") {
                    mountEl.setAttribute("class", "content-box ri-h-500 ri-max-w-2xl");
                    entryEl.insertAdjacentElement("afterend", mountEl);
                }
                else if (entryEl.classList.contains("woocommerce-order-received")) {
                    mountEl.setAttribute("class", "content-box ri-h-500 ri-max-w-2xl ri-mx-auto");
                    // default to adding a sibiling to the known "data-block-name" for default WooCommerce setups
                    const defaultCheckoutBlock = document.querySelector('[data-block-name="woocommerce/order-confirmation-status"]');
                    // beaver blocks (premiumejuice.com)
                    const orderReceivedTitle = document.querySelector(".fl-post-header");
                    // elementor (gamechangersweepstakes.com)
                    const headerElement = document.querySelector("header");
                    if (defaultCheckoutBlock) {
                        defaultCheckoutBlock.insertAdjacentElement("afterend", mountEl);
                    }
                    else if (orderReceivedTitle) {
                        orderReceivedTitle.insertAdjacentElement("afterend", mountEl);
                    }
                    else if (headerElement) {
                        headerElement.insertAdjacentElement("afterend", mountEl);
                    }
                    else {
                        entryEl.prepend(mountEl);
                    }
                    // if only the <body> can be found, then attempt to render under a header
                }
                else if (entryEl.tagName.toLowerCase() === "body") {
                    const headerElement = document.querySelector("header");
                    if (headerElement) {
                        console.log("<header> tag is present.");
                        headerElement.insertAdjacentElement("afterend", mountEl);
                    }
                    else {
                        console.log("<header> tag is not present.");
                        mountEl.setAttribute("class", "content-box ri-h-500");
                        entryEl.prepend(mountEl);
                    }
                    // default on the classic editor
                }
                else {
                    mountEl.setAttribute("class", "content-box ri-h-500");
                    entryEl.prepend(mountEl);
                }
                return [mountEl];
            },
            rootComponent: () => React.lazy(() => import("../App")),
            onIdCheckCreate: () => { },
            onIdCheckError: () => { },
            onIdCheckFail: () => { },
            onIdCheckSuccess: () => { },
        },
        {
            strategy: "registration",
            entrySelectors: WC_REGISTRATION_ENTRY_SELECTORS,
            getAdditionalEnvironmentProps: async () => {
                const shopName = getWcShopName();
                return {
                    checkId: getCheckIdFromLocalStorage() || getCheckIdFromQueryParams(),
                    shopName,
                    shopSettings: await getWcShopSettings(),
                    customerId: getWcCustomerId(),
                };
            },
            isEligble: ({ shopSettings }) => {
                return (!!shopSettings?.deliveryMethods?.includes("registration") ||
                    shopSettings?.deliveryMethod === "registration");
            },
            bootstrap: async () => {
                const shopName = getWcShopName();
                const customerId = getWcCustomerId();
                // const registrationSettings: RegistrationSettings =
                //   await getRegistrationSettings(shopName);
                const shopSettings = await getWcShopSettings();
                const check = await getCheckByCustomer(encodeURIComponent(shopName), customerId);
                if (check &&
                    check.step === "completed" &&
                    check?.job?.result?.success) {
                    setCheckIdInLocalStorage(check?.id);
                    setCheckVerificationStatusInLocalStorage(check?.id, check?.job?.result?.success && check?.step === "completed");
                }
                const props = {
                    // registrationSettings,
                    shopSettings,
                    check,
                };
                return props;
            },
            createMount: ({ entrySelectors, props }) => {
                // is the customer currently on the login or registration pages? If so, do nothing
                console.debug(`Real ID :: testing ${window?.location.pathname} against ${[
                    "/wp-login",
                    "/wp-login/",
                    "/wp-admin",
                    "/wp-admin/",
                    ...(props?.shopSettings?.registrationSettings?.allowedPages || []),
                ].join(",")}`);
                if ([
                    "/wp-login",
                    "/wp-login/",
                    "/wp-admin",
                    "/wp-admin/",
                    ...(props?.shopSettings?.registrationSettings?.allowedPages || []),
                ].includes(window?.location?.pathname)) {
                    console.debug(`Real ID :: on an allowed page, not creating a mount`);
                    return null;
                }
                // is the customer verified by local storage? If so, do nothing
                const localCheckId = getCheckIdFromLocalStorage();
                if (localCheckId) {
                    console.debug(`Real ID :: check ID found in local storage :: check ${localCheckId}`);
                    if (checkVerificationStatusByLocalStorage(localCheckId)) {
                        console.debug(`Real ID :: check already verified :: check ${localCheckId} :: not creating a mount`);
                        return null;
                    }
                }
                if (!props?.shopSettings?.registrationSettings ||
                    isEmpty(props?.shopSettings?.registrationSettings)) {
                    console.debug(`Real ID :: missing registrationSettings, not mounting`);
                    return null;
                }
                const nodes = document.querySelectorAll(entrySelectors.join(", "));
                if (!nodes) {
                    console.debug(`Real ID :: missing query selectors, not mounting`);
                    return null;
                }
                // is the user already verified?
                const mountEls = [...nodes].map((el) => {
                    console.debug(`Real ID :: registration create mount point :: entry ${el}`);
                    const mountEl = document.createElement("div");
                    mountEl.classList.add("real-id-flow");
                    mountEl.innerHTML = "";
                    el.insertAdjacentElement("afterend", mountEl);
                    return mountEl;
                });
                return mountEls;
            },
            onIdCheckCreate: (check) => {
                setCheckIdInLocalStorage(check.id);
            },
            onIdCheckError: (error) => {
                // enable WC checkout depending on the error type
                if (error === "check_already_completed" ||
                    error === "verified_customer") {
                    hideVerifyIdButton();
                }
            },
            onIdCheckSuccess: (check) => {
                if (!check) {
                    console.error("missing check, so unable to continue");
                    return;
                }
                console.debug(`Real ID :: onIdCheckSuccess :: shopify registration :: check ${check.id}`);
                // enable WC checkout
                // store IDv success in localstorage
                hideVerifyIdButton();
                setCheckVerificationStatusInLocalStorage(check.id, true);
                document?.getElementById("real-id-hidden-elements")?.remove();
            },
            rootComponent: () => React.lazy(() => import("../RegistrationApp")),
            onIdCheckFail: () => { },
        },
        {
            strategy: "registration-webhook",
            entrySelectors: WC_REGISTRATION_ENTRY_SELECTORS,
            getAdditionalEnvironmentProps: async () => {
                const shopName = getWcShopName();
                return {
                    checkId: getCheckIdFromLocalStorage() || getCheckIdFromQueryParams(),
                    shopName,
                    shopSettings: await getWcShopSettings(),
                    customerId: getWcCustomerId(),
                };
            },
            isEligble: ({ shopSettings }) => {
                return (!!shopSettings?.deliveryMethods?.includes("registration-webhook") ||
                    shopSettings?.deliveryMethod === "registration-webhook");
            },
            bootstrap: async () => {
                const shopName = getWcShopName();
                const customerId = getWcCustomerId();
                // const registrationSettings: RegistrationSettings =
                //   await getRegistrationSettings(shopName);
                const shopSettings = await getWcShopSettings();
                const check = await createWcCustomerCheck();
                if (check &&
                    check.step === "completed" &&
                    check?.job?.result?.success) {
                    setCheckIdInLocalStorage(check?.id);
                    setCheckVerificationStatusInLocalStorage(check?.id, check?.job?.result?.success && check?.step === "completed");
                }
                const props = {
                    // registrationSettings,
                    shopSettings,
                    check,
                };
                return props;
            },
            createMount: ({ entrySelectors, props }) => {
                // is the customer currently on the login or registration pages? If so, do nothing
                console.debug(`Real ID :: testing ${window?.location.pathname} against ${[
                    "/wp-login",
                    "/wp-login/",
                    "/wp-admin",
                    "/wp-admin/",
                    // ...(props?.shopSettings?.registrationSettings?.allowedPages || []),
                ].join(",")}`);
                if ([
                    "/wp-login",
                    "/wp-login/",
                    "/wp-admin",
                    "/wp-admin/",
                    // ...(props?.shopSettings?.registrationSettings?.allowedPages || []),
                ].includes(window?.location?.pathname)) {
                    console.debug(`Real ID :: on an allowed page, not creating a mount`);
                    return null;
                }
                if (!props?.check) {
                    console.debug(`Real ID :: customer registration-webhook :: didn't return a check, exiting`);
                    return null;
                }
                // is the customer verified by local storage? If so, do nothing
                const localCheckId = getCheckIdFromLocalStorage();
                if (localCheckId) {
                    console.debug(`Real ID :: check ID found in local storage :: check ${localCheckId}`);
                    if (checkVerificationStatusByLocalStorage(localCheckId)) {
                        console.debug(`Real ID :: check already verified :: check ${localCheckId} :: not creating a mount`);
                        return null;
                    }
                }
                const nodes = document.querySelectorAll(entrySelectors.join(", "));
                if (!nodes) {
                    console.debug(`Real ID :: missing query selectors, not mounting`);
                    return null;
                }
                // is the user already verified?
                const mountEls = [...nodes].map((el) => {
                    console.debug(`Real ID :: registration create mount point :: entry ${el}`);
                    const mountEl = document.createElement("div");
                    mountEl.classList.add("real-id-flow");
                    mountEl.innerHTML = "";
                    el.insertAdjacentElement("afterend", mountEl);
                    return mountEl;
                });
                return mountEls;
            },
            onIdCheckCreate: (check) => {
                setCheckIdInLocalStorage(check.id);
            },
            onIdCheckError: (error) => {
                // enable WC checkout depending on the error type
                if (error === "check_already_completed" ||
                    error === "verified_customer") {
                    hideVerifyIdButton();
                }
            },
            onIdCheckSuccess: (check) => {
                if (!check) {
                    console.error("missing check, so unable to continue");
                    return;
                }
                console.debug(`Real ID :: onIdCheckSuccess :: shopify registration :: check ${check.id}`);
                // enable WC checkout
                // store IDv success in localstorage
                hideVerifyIdButton();
                setCheckVerificationStatusInLocalStorage(check.id, true);
                document?.getElementById("real-id-hidden-elements")?.remove();
            },
            rootComponent: () => React.lazy(() => import("../RegistrationApp")),
            onIdCheckFail: () => { },
        },
    ],
};
/**
 * Attempt to find an appropriate mounting strategy and prep the DOM for mounting
 *
 * @param platform
 * @return string
 */
export function detectStrategy(platform, platformBootstrapProps, initialize) {
    const strategies = definitions[platform];
    const strategy = strategies.find((strategy) => {
        const isEligble = strategy.isEligble(platformBootstrapProps, initialize);
        console.debug(`Real ID :: testing strategy ${strategy.strategy} :: attempting to find an element :: ${strategy.entrySelectors.join(", ")}, with isEligble resulting in ${isEligble}`);
        const allElements = document.querySelectorAll(strategy.entrySelectors.join(", "));
        console.debug(`Real ID :: found matching elements on the page? ${allElements.length > 0}`);
        const unmountedEls = [...allElements].filter((el) => !el.getAttribute("real-id-mounted"));
        console.debug(`Real ID :: found mountable matching elements: ${unmountedEls.length > 0}, ${unmountedEls.map((el) => `${el.tagName} #${el.id} ${el.className}, real-id-mounted: ${el.getAttribute("real-id-mounted")}`)}`);
        const hasMountElsOnPage = unmountedEls.length > 0;
        // to support wordpress themes that do not server side render the checkout buttons
        // just continously check the page for a checkout button
        //   if (
        //     strategy.strategy === "pre-checkout" &&
        //     isEligble &&
        //     !hasMountElsOnPage
        //   ) {
        //     let pollCount = 0;
        //     const pollHtml = setInterval(() => {
        //       // if mountable conditions found, clear out interval to prevent infinite buttons
        //       if (detectStrategy(platform, platformBootstrapProps, initialize)) {
        //         clearInterval(pollHtml);
        //         initialize();
        //       } else {
        //         pollCount++;
        //       }
        //       if (pollCount >= 100) {
        //         clearInterval(pollHtml);
        //       }
        //     }, 500);
        //   }
        return isEligble && hasMountElsOnPage;
    });
    // if a eligble strategy is found _with_ mountable elements, then return the strategy for mounting
    if (strategy) {
        console.debug(`Real ID :: detected compatible strategy :: strategy ${strategy?.strategy}`);
        return strategy;
        // if an eligible stategy is not available, then return the null strategy, which is a no-op
    }
    else {
        console.debug(`Real ID :: detectStategy :: no matching strategy found, using null`);
        // if no strategy found, return the "null" strategy as a no-op
        return NullStrategy;
    }
}
